<template>
  <base-dialog
    :show="isEmail"
    @close="closeAllEmail"
    :title="`${t.year} ${t.manufacturer} ${t.model}`"
    :width="true"
  >
    <form @submit.prevent="" class="first-form">
      <div @click="closeAllEmail" class="close-btn">&times;</div>
      <div class="d-sm-flex gap-3">
        <div class="left">
          <div class="input-wrap mb-2">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !firstName && isEmpty }"
              for="firstName"
              >First Name</label
            >
            <input
              v-model="firstName"
              class="form-control"
              type="text"
              id="firstName"
              placeholder="Enter your first name"
            />
          </div>
          <div class="input-wrap mb-2">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !lastName && isEmpty }"
              for="lastName"
              >Last Name</label
            >
            <input
              v-model="lastName"
              class="form-control"
              type="text"
              id="lastName"
              placeholder="Enter your last name"
            />
          </div>
          <div class="input-wrap mb-2">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !email && isEmpty }"
              for="email"
              >Email</label
            >
            <input
              v-model="email"
              class="form-control"
              type="email"
              id="email"
              placeholder="Enter your email address"
            />
          </div>
          <div class="input-wrap">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !phone && isEmpty }"
              for="phone"
              >Phone</label
            >
            <div class="input-group">
              <base-dropdown
                :options="allCountry"
                @input="getCountry"
              ></base-dropdown>
              <input
                v-model="phone"
                id="phone"
                class="form-control"
                type="text"
              />
            </div>
          </div>
          <div class="input-wrap mb-2">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !postalCode && isEmpty }"
              for="postalCode"
              >Postal Code</label
            >
            <input
              v-model="postalCode"
              class="form-control"
              type="text"
              id="postalCode"
              placeholder="Enter your postal code"
            />
            <small>Postal code min 5 max 6 characters</small>
          </div>
        </div>
        <div class="right">
          <div class="input-wrap">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !message && isEmpty }"
              for="message"
              >Message</label
            >
            <textarea
              v-model="message"
              class="form-control"
              id="message"
              maxlength="500"
              placeholder="Enter your inquiry (Up to 500 characters)"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="d-flex gap-3 mt-3">
        <div class="wrap">
          <input v-model="isConfirm" type="checkbox" id="rule" />
          <label
            class="confirm-label"
            for="rule"
            :class="{ 'text-danger': !isConfirm && isEmpty }"
            >Click here to acknowledge you understand our Privacy Policy, which
            explains how the Sandhills Group Companies use and collect personal
            information when you register with us or place an order for any of
            our Brands. We will not share your information for marketing
            purposes with companies outside our group companies and you may
            withdraw your consent at any time.</label
          >
        </div>
        <div class="button-wrap d-flex flex-column align-items-center gap-2">
          <recaptcha-button></recaptcha-button>
          <button
            @click="sendFirstEmail"
            class="d-flex justify-content-center gap-2 align-items-center align-self-end"
          >
            Submit
            <base-loader v-if="loading"></base-loader>
          </button>
        </div>
      </div>
    </form>
  </base-dialog>
  <base-dialog
    :show="isVideoCall"
    @close="closeAllEmail"
    :title="`${t.year} ${t.manufacturer} ${t.model}`"
  >
    <form @submit.prevent="">
      <div @click="closeAllEmail" class="close-btn">&times;</div>
      <div class="d-sm-flex gap-3">
        <div class="left">
          <div class="input-wrap mb-2">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !firstName && isEmpty }"
              for="firstName"
              >First Name</label
            >
            <input
              v-model="firstName"
              class="form-control"
              type="text"
              id="firstName"
              placeholder="Enter your first name"
            />
          </div>
          <div class="input-wrap mb-2">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !lastName && isEmpty }"
              for="lastName"
              >Last Name</label
            >
            <input
              v-model="lastName"
              class="form-control"
              type="text"
              id="lastName"
              placeholder="Enter your last name"
            />
          </div>
          <div class="input-wrap mb-2">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !email && isEmpty }"
              for="email"
              >Email</label
            >
            <input
              v-model="email"
              class="form-control"
              type="email"
              id="email"
              placeholder="Enter your email address"
            />
          </div>
          <div class="input-wrap">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !phone && isEmpty }"
              for="phone"
              >Phone</label
            >
            <div class="input-group">
              <base-dropdown
                :options="allCountry"
                @input="getCountry"
              ></base-dropdown>
              <input
                v-model="phone"
                class="form-control"
                id="phone"
                type="text"
              />
            </div>
          </div>
          <div class="input-wrap mb-2">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !postalCode && isEmpty }"
              for="postalCode"
              >Postal Code</label
            >
            <input
              v-model="postalCode"
              class="form-control"
              type="text"
              id="postalCode"
              placeholder="Enter your postal code"
            />
            <small>Postal code min 5 max 6 characters</small>
          </div>
        </div>
        <div class="right">
          <div class="input-wrap mb-2">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !service && isEmpty }"
              for="lastName"
              >Video Chat Service</label
            >
            <select
              v-model="service"
              class="form-select"
              aria-label="Default select example"
            >
              <option selected value="">Service</option>
              <option value="apple">Apple</option>
              <option value="android">Android</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div class="input-wrap mb-2">
            <label
              class="fw-bold"
              :class="{ 'text-danger': (day?.length == 0 || !time) && isEmpty }"
              for="lastName"
            >
              When would you like to video chat?</label
            >
            <div class="d-flex gap-2">
              <select
                v-model="time"
                class="form-select"
                aria-label="Default select example"
              >
                <option selected value="">Time</option>
                <option value="1">8am-12pm</option>
                <option value="2">12pm-3pm</option>
                <option value="3">3pm-6pm</option>
              </select>
              <select
                v-model="day"
                class="form-select"
                aria-label="Default select example"
              >
                <option selected value="">Day</option>
                <option v-for="(day, i) in days" :key="day" :value="i">
                  {{ day }}
                </option>
              </select>
            </div>
          </div>
          <div class="input-wrap">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !message && isEmpty }"
              for="message"
              >Message</label
            >
            <textarea
              v-model="message"
              class="form-control"
              id="message"
              maxlength="500"
              :placeholder="`I’d like to schedule a video chat to talk about the ${t.year} ${t.manufacturer} ${t.model} you have listed on www.ea-trucks.com.`"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="d-flex gap-3 mt-3">
        <div class="wrap">
          <input v-model="isConfirm" type="checkbox" id="rule" />
          <label
            class="confirm-label"
            for="rule"
            :class="{ 'text-danger': !isConfirm && isEmpty }"
            >Click here to acknowledge you understand our Privacy Policy, which
            explains how the Sandhills Group Companies use and collect personal
            information when you register with us or place an order for any of
            our Brands. We will not share your information for marketing
            purposes with companies outside our group companies and you may
            withdraw your consent at any time.</label
          >
        </div>
        <div class="button-wrap d-flex flex-column align-items-center gap-2">
          <recaptcha-button></recaptcha-button>
          <button
            @click="sendVideoEmail"
            class="d-flex justify-content-center gap-2 align-items-center align-self-end"
          >
            Submit <base-loader v-if="loading"></base-loader>
          </button>
        </div>
      </div>
    </form>
  </base-dialog>
  <div class="truck">
    <div class="each-container d-flex gap-2">
      <div class="w-40">
        <template v-if="t.media?.length">
          <img :src="baseUrl + t.media[0].image" alt="" />
        </template>
        <img
          v-else
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Image_not_available.png/640px-Image_not_available.png"
          alt=""
          class="border"
        />
        <span><b>Updated: </b>{{ formatDate(t.updated_at) }}</span>
      </div>
      <div class="w-60 d-flex justify-content-between">
        <div>
          <h6 class="fw-bold text-decoration-underline">
            <router-link :to="{ name: 'trailer', params: { id: t.id } }"
              >{{ t.year }} {{ t.manufacturer }} {{ t.model }}</router-link
            >
          </h6>
          <span>{{ t.category }}</span>
          <p><b>Price: </b>USD ${{ addSpace(t.price) }}</p>
          <h6 class="fw-bold">Machine Location:</h6>
          <span>{{ t.state }}, {{ t.city }}</span>
          <p><b>Stock Number:</b> {{ t.stock_number }}</p>
          <p><b>Color:</b> {{ t.trailer_chassis?.color }}</p>
          <p><b>Doors:</b> {{ t.trailer_exterior?.doors }}</p>
          <p><b>Floor Type:</b> {{ t.trailer_chassis?.floor_type }}</p>
          <template v-if="isMore">
            <p><b>Height:</b>{{ t.trailer_dimensions?.height }} ft</p>
            <p><b>Length:</b> {{ t.trailer_dimensions?.length }} ft</p>
            <p><b>Tires:</b> {{ t.trailer_chassis?.tires }} lp</p>
            <p><b>Wheels:</b> {{ t.trailer_chassis?.wheels }}</p>
          </template>
          <button @click="isMore = !isMore">
            <fa class="fa" :icon="['fas', chosenIcon]" />
          </button>
        </div>
        <div class="text-end">
          <div class="d-flex gap-1 justify-content-end">
            <input
              v-model="isCompare"
              type="checkbox"
              :id="'compare' + t.id"
              :disabled="compare.length == 4 && isCompare == false"
            />
            <label :for="'compare' + t.id">Compare</label>
          </div>
          <h6>E & A TRUCK SALES</h6>
          <span>5501 Adams, IL 60443</span>
          <p class="mb-1">
            Phone:
            <a href="tel:3126780974" class="text-dark"><u>(312) 678-0974</u></a>
          </p>
          <router-link
            :to="{ name: 'trailer', params: { id: t.id } }"
            class="text-light"
          >
            <button>
              <fa
                class="fa text-light"
                :icon="['fas', 'magnifying-glass-plus']"
              />
              View Details
            </button>
          </router-link>
          <button @click="isEmail = true" class="my-1">
            <fa class="fa text-light" :icon="['fas', 'envelope']" />Email Seller
          </button>
          <button @click="isVideoCall = true" class="mb-1">
            <fa class="fa text-light" :icon="['fas', 'video']" /> Video Chat
          </button>
        </div>
      </div>
    </div>
    <div class="special">
      <span class="text">
        {{ t.description }}
      </span>
      <router-link
        :to="{ name: 'trailer', params: { id: t.id } }"
        class="fw-bold"
        >See More Details</router-link
      >
    </div>
  </div>
</template>

<script>
import customAxios from "../../api";
import { defineAsyncComponent } from "vue";
import BaseDropdown from "../UI/BaseDropdown.vue";
import CountryList from "country-list-with-dial-code-and-flag";
const BaseDialog = defineAsyncComponent(() => import("../UI/BaseDialog.vue"));
const BaseLoader = defineAsyncComponent(() => import("../UI/BaseLoader.vue"));
import { addSpace, formatDate, checkEmail } from "../../utils";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  props: ["t", "days"],
  components: {
    BaseDialog,
    BaseDropdown,
    BaseLoader,
  },
  data() {
    return {
      isMore: false,
      isEmail: false,
      isVideoCall: false,
      allCountry: CountryList,
      isCompare: false,
      baseUrl: process.env.VUE_APP_IMAGE_URL,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      phoneCode: "+1",
      postalCode: "",
      message: "",
      service: "",
      time: "",
      day: "",
      isConfirm: false,
      isEmpty: false,
    };
  },
  computed: {
    loading() {
      return this.$store.state.isLoading;
    },
    chosenIcon() {
      return this.isMore ? "angle-up" : "angle-down";
    },
    compare() {
      return this.$store.getters.compareTrailers;
    },
    filteredPhone() {
      return this.phoneCode + this.phone;
    },
    startDay() {
      let time, day;
      day = new Date(new Date().getTime() + this.day * 86400000)
        .toJSON()
        .slice(0, 10);
      if (this.time == 1) time = "08:00 " + day;
      else if (this.time == 2) time = "12:00 " + day;
      else if (this.time == 3) time = "15:00 " + day;
      return time;
    },
    finishDay() {
      let time, day;
      day = new Date(new Date().getTime() + this.day * 86400000)
        .toJSON()
        .slice(0, 10);
      if (this.time == 1) time = "12:00 " + day;
      else if (this.time == 2) time = "15:00 " + day;
      else if (this.time == 3) time = "18:00 " + day;
      return time;
    },
  },
  methods: {
    addSpace,
    formatDate,
    closeAllEmail() {
      this.isEmail = false;
      this.isVideoCall = false;
      this.reset();
    },
    getCountry(val) {
      this.phoneCode = val;
    },
    reset() {
      this.isConfirm = false;
      this.isEmpty = false;
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.phone = "";
      this.phoneCode = "+1";
      this.message = "";
      this.postalCode = "";
      this.time = "";
      this.day = "";
      this.service = "";
    },
    isCompareAvailable() {
      const arr = this.compare?.filter(
        (each) => [this.t.id].indexOf(each) != -1
      );
      if (arr.length) this.isCompare = true;
    },
    async sendFirstEmail() {
      if (!this.isConfirm) {
        this.isEmpty = true;
        return;
      }
      if (
        !this.firstName ||
        !this.lastName ||
        !this.email ||
        !this.message ||
        !this.phone ||
        !this.postalCode ||
        checkEmail(this.email)
      ) {
        this.isEmpty = true;
        return;
      }
      try {
        this.$store.dispatch("changeLoading", true);
        await customAxios.post("create-trailer-email/", {
          trailer: this.t.id,
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          phone: this.filteredPhone,
          postal_code: this.postalCode,
          message: this.message,
        });
        this.$store.dispatch("changeLoading", false);
        this.closeAllEmail();
        toast.success("Success!");
      } catch (e) {
        this.$store.dispatch("changeLoading", false);
        this.$store.dispatch("setError", e);
      }
    },
    async sendVideoEmail() {
      if (!this.isConfirm) {
        this.isEmpty = true;
        return;
      }
      if (
        !this.firstName ||
        !this.lastName ||
        !this.email ||
        !this.message ||
        !this.phone ||
        !this.postalCode ||
        !this.service ||
        !this.time ||
        !this.day ||
        checkEmail(this.email)
      ) {
        this.isEmpty = true;
        return;
      }
      try {
        this.$store.dispatch("changeLoading", true);
        await customAxios.post("create-trailer-video-chat-email/", {
          trailer: this.t.id,
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          phone: this.filteredPhone,
          postal_code: this.postalCode,
          message: this.message,
          video_chat_service: this.service,
          start_date: this.startDay,
          end_date: this.finishDay,
        });
        this.$store.dispatch("changeLoading", false);
        this.closeAllEmail();
        toast.success("Success!");
      } catch (e) {
        this.$store.dispatch("changeLoading", false);
        this.$store.dispatch("setError", e);
      }
    },
  },
  created() {
    this.isCompareAvailable();
  },
  watch: {
    compare(val) {
      const arr = val?.filter((each) => [this.t.id].indexOf(each) != -1);
      if (!arr.length) this.isCompare = false;
    },
    isCompare(val) {
      if (val) {
        this.$store.commit("addCompareTrailers", { add: true, id: this.t.id });
      } else {
        this.$store.commit("addCompareTrailers", { add: false, id: this.t.id });
      }
    },
  },
};
</script>

<style scoped>
.input-group {
  align-items: center;
}
.truck {
  padding: 12px;
  border-radius: 0.2rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-bottom: 0.8rem;
}
label {
  user-select: none;
}
.text-end button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  background: #0057b7;
  color: #fff;
  padding: 3px !important;
  border-radius: 0.12rem;
  font-size: 0.7rem;
  width: 140px;
}
.w-40 {
  width: 38%;
}
.w-60 {
  width: 62%;
}
.w-60 button,
.text-end button {
  vertical-align: middle;
  padding: 0 2.2rem;
}
.fa {
  font-size: 0.8rem;
}
img {
  width: 100%;
  cursor: pointer;
}
h6,
p,
label {
  font-size: 0.85rem;
}
select {
  font-size: 0.85rem !important;
}
span {
  font-size: 0.8rem;
}
.w-40 span {
  font-size: 0.76rem;
}
.text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.special a {
  font-size: 0.9rem;
  color: #000;
}
/*  */
form {
  padding: 0;
  margin-top: 0.8rem;
}
form .left,
form .right,
.button-wrap,
.wrap {
  flex: 1;
}
.input-wrap {
  display: flex;
  flex-direction: column;
}
input,
textarea,
select {
  margin-top: 1px;
  padding: 4px;
  font-size: 0.9rem;
}
label {
  font-size: 0.9rem;
}
::placeholder {
  font-size: 0.8rem;
}
.right .input-wrap:last-child {
  height: 57%;
}
textarea {
  height: 100%;
}
.wrap {
  line-height: 14px;
}
.wrap input {
  margin-right: 4px;
}
.button-wrap button {
  width: 100%;
}
.wrap input,
.wrap label {
  display: inline;
  font-size: 0.68rem;
}
button.align-self-end {
  background: #0057b7;
  color: #fff;
  padding: 4px;
  border-radius: 0.2rem;
}
.recaptcha-wrapper label {
  font-size: 0.85rem;
}

.first-form .right label {
  height: 7%;
}
.first-form .right .input-wrap {
  height: 97%;
}
.first-form .right textarea {
  height: 100%;
}
@media (max-width: 490px) {
  .each-container {
    flex-direction: column;
  }
  .each-container .w-40 {
    margin-bottom: 4px;
  }
  .each-container .w-40,
  .each-container .w-60 {
    width: 100%;
  }
}
</style>
