<template>
  <div class="trucks-container" :class="{ show: isOpenSecondFilter }">
    <section>
      <div class="home-sub-title text-center">
        <p>5501 Adams ST Unit C Matteson, IL 60443</p>
      </div>
      <div class="home-welcome text-center">
        <h2>TRAILERS</h2>
      </div>
    </section>
    <section class="my-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="trucks-left col-md-3">
            <div
              class="second-left-filter filter-container mt-4 px-2"
              :class="{ show: isOpenSecondFilter }"
            >
              <h5 class="fw-bold">TRAILERS FOR SALE</h5>
              <p class="mb-2">
                {{ allTrailersLength ? 1 : 0 }} - {{ allTrailersLength }} OF
                {{ allTrailersLength }} LISTINGS
              </p>
              <div class="filter-wrapper">
                <div class="applied-filters p-2">
                  <div class="d-block d-sm-none title my-2">
                    <button
                      @click="isOpenSecondFilter = false"
                      class="w-100 h-100 p-2"
                    >
                      DONE
                    </button>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-between mb-2"
                  >
                    <h6 class="fw-bold">Applied Filters</h6>
                    <span
                      v-if="allSelected.length"
                      @click="clearAll"
                      class="text-decoration-underline"
                      >Clear All</span
                    >
                  </div>
                  <div class="d-flex gap-1 flex-wrap">
                    <div
                      v-for="item in allSelected2"
                      :key="item"
                      class="filters"
                    >
                      <fa
                        @click="deleteFilter(item)"
                        class="fa text-light"
                        :icon="['fas', 'xmark']"
                      />{{ item }}
                    </div>
                  </div>
                </div>
                <div class="search-wrap">
                  <div class="header-wrap p-1 px-2">
                    <h6>Quick Search</h6>
                  </div>
                  <div class="search-input d-flex gap-2">
                    <input
                      v-model.lazy="search"
                      type="text"
                      placeholder="Enter keyword(s)"
                    />
                    <button @click="getSearch">Search</button>
                  </div>
                </div>
                <base-accordion
                  title="Listing Type and Auction Results"
                  :options="allTrailerFilters.listing_type_options"
                  :isAvailable="filter.listingType"
                  @input="getListingType"
                ></base-accordion>
                <base-accordion
                  title="Category"
                  :options="allTrailerFilters.category_options"
                  :isAvailable="filter.category"
                  @input="getCategory"
                ></base-accordion>
                <base-accordion
                  title="Manufacturer"
                  :options="allTrailerFilters.manufacturer_options"
                  :isAvailable="filter.manufacturer"
                  @input="getManufacturer"
                ></base-accordion>
                <base-accordion
                  title="Condition"
                  :options="allTrailerFilters.condition_options"
                  :isAvailable="filter.condition"
                  @input="getCondition"
                ></base-accordion>
                <base-accordion
                  title="Country"
                  :options="allTrailerFilters.country_options"
                  :isAvailable="filter.country"
                  @input="getCountry"
                ></base-accordion>
                <div class="search-wrap">
                  <div
                    @click="isDateOpen = !isDateOpen"
                    class="header-wrap px-2"
                  >
                    <h6 class="py-1">Select a Date Range:</h6>
                  </div>
                  <template v-if="isDateOpen">
                    <div class="search-input">
                      <div class="w-100 d-flex justify-content-between gap-1">
                        <div class="position-relative">
                          <datepicker
                            style="width: 90%"
                            class="p-0"
                            v-model="filter.date.min"
                            :clearable="true"
                          />
                          <fa class="mini" :icon="['fas', 'calendar-alt']" />
                        </div>
                        <div class="position-relative">
                          <datepicker
                            style="width: 90%"
                            class="p-0"
                            v-model="filter.date.max"
                            :upper-limit="upperLimit"
                            :disabled="filter.lastDays ? true : false"
                            :clearable="true"
                          />
                          <fa class="mini" :icon="['fas', 'calendar-alt']" />
                        </div>
                      </div>
                    </div>
                    <span class="px-2 fw-bold">Or Enter Number of Days:</span>
                    <div class="mt-2 d-flex align-items-center gap-2 px-2">
                      <p>Show listings in the last</p>
                      <input
                        style="width: 3rem"
                        class="p-0"
                        v-model="filter.lastDays"
                        type="number"
                      />
                      <p>days</p>
                    </div>
                  </template>
                </div>
                <div
                  class="header-wrap text-secondary text-center mt-4 p-1 px-2"
                >
                  <h6>Additional Filters</h6>
                </div>
                <search-accordion
                  :search="true"
                  :isInput="filter.serial"
                  @input="getSerial"
                  title="Serial #"
                  placeholder1="Enter Serial #"
                ></search-accordion>
                <search-accordion
                  title="Gross Vehicle Weight"
                  :isAvailable="filter.gvw"
                  @input="getGvw"
                  placeholder1="Min (lb)"
                  placeholder2="Max (lb)"
                ></search-accordion>
                <search-accordion
                  :search="true"
                  :isInput="filter.stock"
                  @input="getStock"
                  title="Stock #"
                  placeholder1="Enter Stock #"
                ></search-accordion>
              </div>
            </div>
          </div>
          <div class="trucks-right col-md-6">
            <all-trailers
              :search="search"
              @sortTrailer="triggerTrailer"
            ></all-trailers>
          </div>
        </div>
      </div>
    </section>
    <div class="w-100 second-footer p-3 d-sm-none d-block">
      <div @click="isOpenSort = !isOpenSort" class="w-50 text-center pointer">
        <div>
          <fa class="fa" :icon="['fas', 'arrow-down-wide-short']" />
        </div>
        <p>Sort</p>
      </div>
      <div
        @click="isOpenSecondFilter = !isOpenSecondFilter"
        class="circle-filter text-center pointer"
      >
        <fa class="fa filter-icon" :icon="['fas', 'filter']" />
        <fa class="fa" :icon="['fas', 'sort']" />
        <p>FILTER</p>
      </div>
    </div>
    <div v-if="isOpenSort" class="mobile-sort">
      <p v-for="opt in allSorts" :key="opt" @click="getSort(opt.id)">
        {{ opt.name }}
      </p>
    </div>
    <base-footer></base-footer>
  </div>
</template>

<script>
import customAxios from "../api";
import BaseAccordion from "../components/UI/BaseAccordion.vue";
import SearchAccordion from "../components/UI/SearchAccordion.vue";
import AllTrailers from "../components/trailers/AllTrailers.vue";
import Datepicker from "vue3-datepicker";

const DIFF = "^^";
export default {
  components: {
    BaseAccordion,
    SearchAccordion,
    AllTrailers,
    Datepicker,
  },
  data() {
    return {
      isOpenSort: false,
      isOpenSecondFilter: false,
      search: "",
      upperLimit: null,
      isDateOpen: false,
      allTrailerFilters: {},
      allSelected: [],
      inputFilter: ["serial", "stock"],
      filter: {
        listingType: "",
        category: "",
        manufacturer: "",
        condition: "",
        country: "",
        gvw: {
          min: "",
          max: "",
        },
        stock: "",
        date: {
          min: null,
          max: null,
        },
        lastDays: "",
        serial: "",
      },
      allSorts: [
        { name: "By Default", id: "default" },
        { name: "Price: Low to High", id: "price" },
        { name: "Price: High to Low", id: "-price" },
        { name: "Year: Low to High", id: "year" },
        { name: "Year: High to Low", id: "-year" },
        { name: "Manufacturer", id: "manufacturer" },
        { name: "Model", id: "model" },
        { name: "Stock Number", id: "stock_number" },
        { name: "Recently Added", id: "-created_at" },
        { name: "Recently Updated", id: "-updated_at" },
      ],
    };
  },
  computed: {
    allTrailersLength() {
      return this.$store.getters.allTrailers?.length;
    },
    allSelected2() {
      return this.allSelected.map((item) => {
        return item.replace("^^", "");
      });
    },
  },
  methods: {
    getSort(val) {
      this.isOpenSort = false;
      this.$store.commit("addSortTrailer", val);
    },
    getSearch() {
      this.$store.commit("getSearchTrailers", this.search);
    },
    triggerTrailer() {
      this.$store.dispatch("postFilterTrailers", this.filter);
    },
    clearAll() {
      this.allSelected.length = 0;
      let arr = Object.keys(this.filter);
      arr.forEach((item) => {
        if (item == "date") {
          this.filter[item].min = null;
          this.filter[item].max = null;
          return;
        }
        if (item == "gvw") {
          this.filter[item].min = "";
          this.filter[item].max = "";
          return;
        }
        this.filter[item] = "";
      });
    },
    deleteFilter(val) {
      if (val == "date range") {
        this.allSelected = this.allSelected.filter((item) => item != val);
        this.filter.date.min = null;
        this.filter.date.max = null;
        this.filter.lastDays = "";
        return;
      }
      if (this.inputFilter.includes(val)) {
        this.allSelected = this.allSelected.filter((item) => item != val);
        if (this.filter[val]) {
          this.filter[val] = "";
        }
        return;
      }
      if (val == "gvw") {
        this.allSelected = this.allSelected.filter((item) => item != val);
        if (this.filter[val]) {
          this.filter[val].min = "";
          this.filter[val].max = "";
        }
        return;
      }
      this.allSelected = this.allSelected.filter((item) => item != val + DIFF);
      let arr = Object.keys(this.filter);
      arr.forEach((item) => {
        if (
          this.filter[item].length &&
          this.filter[item].includes(val + DIFF)
        ) {
          let newStr = this.filter[item].replace(val + DIFF, "");
          this.filter[item] = newStr;
        }
      });
    },
    setInputFilter(val) {
      return this.allSelected.indexOf(val);
    },
    setFilterMM(val) {
      this.filter[val.name].min = val.min;
      this.filter[val.name].max = val.max;
    },
    getGvw(val) {
      this.setFilterMM({ name: "gvw", min: val.min, max: val.max });
      if (this.setInputFilter("gvw") == -1) this.allSelected.push("gvw");
    },
    getStock(val) {
      this.filter.stock = val;
      if (this.setInputFilter("stock") == -1) this.allSelected.push("stock");
    },
    getSerial(val) {
      this.filter.serial = val;
      if (this.setInputFilter("serial") == -1) this.allSelected.push("serial");
    },
    getCountry(val) {
      if (this.getAvailability(val.name)) {
        this.deleteFilter(val.name);
      } else {
        this.filter.country += val.name + DIFF;
        this.allSelected.push(val.name + DIFF);
      }
    },
    getCondition(val) {
      if (this.getAvailability(val.name)) {
        this.deleteFilter(val.name);
      } else {
        this.filter.condition += val.name + DIFF;
        this.allSelected.push(val.name + DIFF);
      }
    },
    getCategory(val) {
      if (this.getAvailability(val.name)) {
        this.deleteFilter(val.name);
      } else {
        this.filter.category += val.name + DIFF;
        this.allSelected.push(val.name + DIFF);
      }
    },
    getManufacturer(val) {
      if (this.getAvailability(val.name)) {
        this.deleteFilter(val.name);
      } else {
        this.filter.manufacturer += val.name + DIFF;
        this.allSelected.push(val.name + DIFF);
      }
    },
    getListingType(val) {
      if (this.getAvailability(val.name)) {
        this.deleteFilter(val.name);
      } else {
        this.filter.listingType += val.name + DIFF;
        this.allSelected.push(val.name + DIFF);
      }
    },
    getAvailability(val) {
      return this.allSelected.includes(val + DIFF);
    },
    async getAllTrailerFilters() {
      try {
        const res = await customAxios.get("trailer-filter-options/");
        this.allTrailerFilters = res.data;
      } catch (e) {
        alert(e);
      }
    },
  },
  created() {
    this.getAllTrailerFilters();
  },
  watch: {
    filter: {
      handler(val) {
        if (val.lastDays) {
          this.upperLimit = new Date();
        } else if (!val.lastDays) {
          this.upperLimit = null;
        }
        if ((val.date?.min && val.date?.max) || val.lastDays) {
          if (!this.allSelected.includes("date range")) {
            this.allSelected.push("date range");
          }
        }
        this.$store.dispatch("postFilterTrailers", val);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.trucks-container.show {
  height: 110vh;
  overflow-y: hidden;
}
h5 {
  color: #0057b7;
}
h6,
.circle-filter p {
  font-size: 0.85rem;
  font-weight: 501;
  user-select: none;
  cursor: pointer;
}
.header-wrap {
  background: #f6f6f6;
  border-bottom: 1px solid #ddd;
}
.header-wrap:last-child {
  border-bottom: 1px solid #444;
}
.filter-wrapper {
  border: 1px solid #ddd;
}
.filter-container p {
  color: rgb(110, 109, 109);
}
.search-input {
  padding: 10px 8px;
}
.search-input input,
button {
  height: 24px;
  border: 1px solid #ddd;
  font-size: 0.9rem;
}
.search-input input {
  flex: 3;
}
.search-input button {
  line-height: 12px;
  flex: 1;
}
input::placeholder,
.second-footer {
  font-size: 0.9rem;
}
@media screen and (max-width: 578px) {
  .second-left-filter {
    height: 100vh;
  }
}
@media screen and (min-width: 405px) {
  .trucks-container.show {
    height: 134vh;
  }
}
</style>
