<template>
  <base-dialog
    :show="isEmailToFriend"
    @close="closeEmail"
    title="Email this to a friend"
    :width="true"
  >
    <form @submit.prevent="">
      <div @click="isEmailToFriend = false" class="close-btn">&times;</div>
      <div class="d-sm-flex gap-3">
        <div class="left">
          <div class="input-wrap mb-2">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !firstName && isEmpty }"
              for="firstName"
              >First Name</label
            >
            <input
              v-model="firstName"
              class="form-control border"
              type="text"
              id="firstName"
              placeholder="Enter your first name"
            />
          </div>
          <div class="input-wrap mb-2">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !lastName && isEmpty }"
              for="lastName"
              >Last Name</label
            >
            <input
              v-model="lastName"
              class="form-control border"
              type="text"
              id="lastName"
              placeholder="Enter your last name"
            />
          </div>
          <div class="input-wrap mb-2">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !email && isEmpty }"
              for="email"
              >Email</label
            >
            <input
              v-model="email"
              class="form-control border"
              type="email"
              id="email"
              placeholder="Enter your email address"
            />
          </div>
          <div class="input-wrap">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !recEmail && isEmpty }"
              for="recEmail"
              >Recipient's Email</label
            >
            <input
              v-model="recEmail"
              class="form-control border"
              type="email"
              id="recEmail"
              placeholder="Enter recipient's email address"
            />
          </div>
        </div>
        <div class="right">
          <div class="input-wrap">
            <label
              class="fw-bold"
              :class="{ 'text-danger': !message && isEmpty }"
              for="message"
              >Message</label
            >
            <textarea
              v-model="message"
              class="form-control border"
              id="message"
              maxlength="500"
              placeholder="Enter your inquiry (Up to 500 characters)"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="d-flex gap-3 mt-3">
        <div class="wrap">
          <input v-model="isConfirm" type="checkbox" id="rule" />
          <label
            class="confirm-label"
            for="rule"
            :class="{ 'text-danger': !isConfirm && isEmpty }"
            >Click here to acknowledge you understand our Privacy Policy, which
            explains how the Sandhills Group Companies use and collect personal
            information when you register with us or place an order for any of
            our Brands. We will not share your information for marketing
            purposes with companies outside our group companies and you may
            withdraw your consent at any time.</label
          >
        </div>
        <div class="button-wrap d-flex flex-column align-items-center gap-2">
          <recaptcha-button></recaptcha-button>
          <button
            @click="submitEmail"
            class="d-flex justify-content-center gap-2 align-items-center align-self-end"
          >
            Submit <base-loader v-if="loading"></base-loader>
          </button>
        </div>
      </div>
    </form>
  </base-dialog>
  <div>
    <div class="title d-flex justify-content-end gap-2 mt-3 mt-md-0">
      <button @click="printPdf">
        <fa class="fa text-light" :icon="['fas', 'print']" /> Print
      </button>
      <button @click="isEmailToFriend = true">
        <fa class="fa text-light" :icon="['fas', 'envelope']" /> Email
      </button>
    </div>
    <div class="header-filter d-sm-flex align-items-center gap-3 mb-3">
      <div
        class="d-flex justify-content-center align-items-center gap-1 mb-3 mb-sm-0"
      >
        <router-link to="/compare-trailer" class="mx-1">
          <button class="p-2 py-1 rounded">
            Compare {{ compareImg?.length ?? 0 }}/4
          </button>
        </router-link>
        <template v-if="compareImg?.length">
          <div
            v-for="img in compareImg"
            :key="img.id"
            class="compare-images position-relative pointer"
            @click="closeCompare(img.id)"
          >
            <img :src="baseUrl + img.img" alt="" />
            <span class="close-img">&times;</span>
          </div>
        </template>
      </div>
      <div
        class="d-flex align-items-center justify-content-between gap-2 flex-grow-1"
      >
        <h6 class="fw-bold w-50 text-center text-sm-end">Sort By:</h6>
        <select v-model="sortedTrailers" class="form-select w-50">
          <option value="default">Default</option>
          <option value="price">Price: Low to High</option>
          <option value="-price">Price: High to Low</option>
          <option value="year">Year: Low to High</option>
          <option value="-year">Year: High to Low</option>
          <option value="manufacturer">Manufacturer</option>
          <option value="model">Model</option>
          <option value="stock_number">Stock Number</option>
          <option value="-created_at">Recently Added</option>
          <option value="-updated_at">Recently Updated</option>
        </select>
      </div>
    </div>
    <div
      v-if="getTrailers?.length == 0"
      class="no-arr d-flex align-items-center gap-2 justify-content-center p-4 text-light"
    >
      <fa class="fa fs-2" :icon="['fas', 'circle-info']" />
      <h2 class="fw-bold">No Listings Trailers Found</h2>
    </div>
    <div v-else-if="getTrailers" class="trucks-wrapper">
      <each-trailer
        v-for="trailer in getTrailers"
        :key="trailer.id"
        :t="trailer"
        :days="days"
      ></each-trailer>
    </div>
  </div>
</template>
<script>
import customAxios from "../../api";
import { defineAsyncComponent } from "vue";
import { getDays, checkEmail } from "../../utils";
import EachTrailer from "../trailers/EachTrailer.vue";
const BaseDialog = defineAsyncComponent(() => import("../UI/BaseDialog.vue"));
const BaseLoader = defineAsyncComponent(() => import("../UI/BaseLoader.vue"));
import { useToast } from "vue-toastification";

const toast = useToast();
export default {
  components: {
    EachTrailer,
    BaseDialog,
    BaseLoader,
  },
  props: ["search"],
  emits: ["sortTrailer"],
  data() {
    return {
      isEmailToFriend: false,
      days: getDays(),
      isEmpty: false,
      isConfirm: false,
      firstName: "",
      lastName: "",
      email: "",
      recEmail: "",
      message: "",
      baseUrl: process.env.VUE_APP_IMAGE_URL,
      sortedTrailers: "default",
    };
  },
  computed: {
    loading() {
      return this.$store.state.isLoading;
    },
    isAvailableSorted() {
      return this.$store.state.sortTrailer;
    },
    searchedTrailers() {
      return this.$store.getters.allTrailers?.filter((item) => {
        return (
          item.manufacturer.toLowerCase().includes(this.search.toLowerCase()) ||
          item.model.toLowerCase().includes(this.search.toLowerCase()) ||
          item.category.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
    getTrailers() {
      return this.searchedTrailers;
    },
    compare() {
      return this.$store.getters.compareTrailers;
    },
    compareImg() {
      return this.$store.getters.compareTrailerImages;
    },
  },
  methods: {
    closeCompare(id) {
      this.$store.commit("addCompareTrailers", { add: false, id: id });
    },
    reset() {
      this.isConfirm = false;
      this.isEmpty = false;
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.recEmail = "";
      this.message = "";
    },
    async submitEmail() {
      if (!this.isConfirm) {
        this.isEmpty = true;
        return;
      }
      if (
        !this.firstName ||
        !this.lastName ||
        !this.email ||
        !this.recEmail ||
        !this.message ||
        checkEmail(this.email) ||
        checkEmail(this.recEmail)
      ) {
        this.isEmpty = true;
        return;
      }
      try {
        this.$store.dispatch("changeLoading", true);
        await customAxios.post("create-friend-email/", {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          recipient_email: this.recEmail,
          message: this.message,
        });
        this.$store.dispatch("changeLoading", false);
        this.closeEmail();
        toast.success("Success!");
      } catch (e) {
        this.$store.dispatch("changeLoading", false);
        this.$store.dispatch("setError", e);
      }
    },
    closeEmail() {
      this.isEmailToFriend = false;
      this.reset();
    },
    printPdf() {
      window.print();
    },
  },
  created() {
    this.$store.dispatch("getTrailers");
  },
  watch: {
    isAvailableSorted(val) {
      this.sortedTrailers = val;
    },
    sortedTrailers(val) {
      this.$store.commit("addSortTrailer", val);
      this.$emit("sortTrailer");
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
.compare-images {
  width: 42px;
  height: 28px;
}
.compare-images img {
  height: 100%;
}
.compare-images:hover {
  opacity: 0.9;
}
.close-img {
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  padding: 2px 3px;
  line-height: 12px;
  background: rgba(0, 0, 0, 0.5);
}
.header-filter {
  position: sticky;
  top: 0;
  background: #fff;
  padding: 1rem 0;
  border-bottom: 1px solid #0057b7;
}
.form-select {
  width: 40%;
  font-size: 0.85rem;
  padding: 2px;
}
/*  */
form {
  padding: 0;
  margin-top: 0.8rem;
}
form .left,
form .right,
.button-wrap,
.wrap {
  flex: 1;
}
.input-wrap {
  display: flex;
  flex-direction: column;
}
input,
textarea {
  margin-top: 1px;
  padding: 4px;
  font-size: 0.9rem;
}
label {
  font-size: 0.9rem;
}
::placeholder,
.header-filter button {
  font-size: 0.8rem;
}
.right label {
  height: 10%;
}
.right .input-wrap {
  height: 100%;
}
.right textarea {
  height: 100%;
}
.wrap {
  line-height: 14px;
}
.wrap input {
  background: red !important;
  margin-right: 4px;
}
.button-wrap button {
  width: 100%;
}
.wrap input,
.wrap label {
  display: inline;
  font-size: 0.68rem;
}
button.align-self-end {
  background: #0057b7;
  color: #fff;
  padding: 4px;
  border-radius: 0.2rem;
}
.recaptcha-wrapper label {
  font-size: 0.85rem;
}
</style>
